import { useState, useCallback } from "react";
import { useNotify } from "services/notification";
import { useRefreshOrder } from "services/orders";
import { MoveType } from "models";
import { CounselingStatusId, counselingStatusToPathMap } from "types";
import { useRouter } from "next/navigation";
import { useOrder } from "store";

interface Options {
  /**
   * The next counseling status we are expecting to transition to after
   * the current step has been successfully completed.
   */
  nextStatus: CounselingStatusId;
  /**
   * Pass an asynchronous callback function to be fired instead of saving the move type.
   *
   * After the callback promise resolves, the order is reloaded
   * and the user is pushed to the next step. You may want to prevent
   * this follow on behavior, for example in the case where another
   * api call returned an error and you need the user to stay on the step.
   * In this case return false.
   */
  saveCallback?(moveType: MoveType): Promise<boolean | void>;
}

export const useCounselingSubmit = (options: Options) => {
  const { saveCallback, nextStatus } = options;
  const notify = useNotify();
  const router = useRouter();
  const order = useOrder();
  const { refresh: refreshOrder } = useRefreshOrder();
  const [loading, setLoading] = useState(false);
  const moveType = order.moveType;

  const handleNext = useCallback(async () => {
    setLoading(true);
    try {
      if (typeof saveCallback !== "undefined") {
        const shouldContinue = await saveCallback(moveType);
        if (shouldContinue === false) {
          setLoading(false);
          return;
        }
      }
      moveType.counselingStatus = nextStatus;
      await moveType.save();
      const newRoute = counselingStatusToPathMap[moveType.counselingStatus];
      if (moveType.counselingStatus === "COMPLETE") {
        // we have to refresh the order here so that it displays correctly after the modifications in counseling
        await refreshOrder();
      }
      router.push(newRoute);
    } catch (e) {
      notify("Something went wrong please try again", {
        variant: "error",
        autoHideDuration: 1500,
      });
      setLoading(false);
      console.error(e);
      return;
    }
  }, [nextStatus, moveType, saveCallback, notify, router, refreshOrder]);

  return { handleNext, loading };
};
