import { useEffect, useMemo } from "react";
import { useAtom, useAtomValue, atom } from "jotai";
import { atomWithStorage, RESET } from "jotai/utils";
import { useOrder } from "services/orders/OrderGuard";
import { groupBy } from "lodash";
import { P } from "components/common/Typography/typography";
import { ShipmentType } from "types";

export type ChangedItems = {
  surveyId: string;
  changedItems: {
    [key: string]: number;
  };
};
export const changedItemsAtom = atomWithStorage<ChangedItems>("changed-items", {
  surveyId: "",
  changedItems: {},
});

export const useSurvey = () => {
  const order = useOrder();
  const primarySurvey = order?.primarySurvey;
  const pendingSurvey = order?.pendingSurvey;
  const survey = pendingSurvey ?? primarySurvey;
  const [changedItems, setChangedItemsAtom] = useAtom(changedItemsAtom);
  const surveyId = survey?.id;
  useEffect(() => {
    if (changedItems.surveyId.length === 0 && surveyId) {
      setChangedItemsAtom((state) => ({
        ...state,
        surveyId,
      }));
    } else if (changedItems.surveyId !== surveyId) {
      setChangedItemsAtom(RESET);
    }
  }, [surveyId, setChangedItemsAtom]);

  return survey;
};

export const useSurveyRooms = () => {
  const survey = useSurvey();
  if (!survey) {
    return undefined;
  }

  const rooms = groupBy(survey.items, ({ room }) => room.id);
  return rooms;
};

export const useSurveyShipments = () => {
  const survey = useSurvey();
  if (!survey) {
    return {};
  }

  const shipments = groupBy(
    survey.segments,
    ({ order: { shipmentType } }) => shipmentType
  );

  return shipments;
};

export const useShipmentRoomSurveyItem = (
  roomId: string,
  shipmentType: ShipmentType
) => {
  const shipments = useSurveyShipments();

  if (!shipments) {
    return [];
  }

  const rooms = groupBy(
    shipments[shipmentType].flatMap((segment) => segment.items),
    (item) => item.roomId
  );

  return rooms?.[roomId];
};

export const useSurveyRoom = (roomId: string) => {
  const rooms = useSurveyRooms();

  return rooms?.[roomId];
};

export const useSurveyItem = (itemId: string) => {
  const survey = useSurvey();
  return survey?.items?.find(({ id }) => id === itemId);
};

export function getChangedItemQuantity(
  changedItems: Record<string, number>,
  shipmentType: ShipmentType,
  roomId: string,
  itemId: string
) {
  return changedItems[`${shipmentType}:${roomId}:${itemId}`] ?? 0;
}
export const useChangedItemsAtom = () => useAtom(changedItemsAtom);
export const useGetChangedItemQuantityAtom = (
  roomId: string,
  itemId: string,
  shipmentType: ShipmentType
) => {
  const key = `${shipmentType}:${roomId}:${itemId}`;
  return useMemo(
    () =>
      atom(
        (get) => get(changedItemsAtom).changedItems[key] ?? 0,
        (get, set, newQuantity: number) => {
          const currentState = get(changedItemsAtom);
          set(changedItemsAtom, {
            surveyId: currentState.surveyId,
            changedItems: {
              ...currentState.changedItems,
              [key]: newQuantity,
            },
          });
        }
      ),
    [key]
  );
};
export const useChangedItemQuantityAtom = (
  roomId: string,
  itemId: string,
  shipmentType: ShipmentType
) => useAtom(useGetChangedItemQuantityAtom(roomId, itemId, shipmentType));

export const totalChangedItemsAtom = atom((get) => {
  const changedItems = get(changedItemsAtom);
  return Object.values(changedItems.changedItems).reduce<number>(
    (removedItems, num) => removedItems + Math.abs(num),
    0
  );
});
export const useTotalChangedItems = () => useAtomValue(totalChangedItemsAtom);
