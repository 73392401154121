import styled from "@emotion/styled";

const ThumbnailElement = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
`;

type ThumbnailProps = {
  src: string;
};
export const Thumbnail = ({ src }: ThumbnailProps): JSX.Element => {
  return (
    <ThumbnailElement
      data-testid="thumbnail-element"
      style={{ backgroundImage: `url('${src}')` }}
    />
  );
};
