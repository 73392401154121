import { useMemo } from "react";
import { useOrder } from "services/orders/OrderGuard";
import { InventoryItem } from "models";

/**
 * Hook to get an items current claim from the order data (if present)
 * @param item The item to find the claim for
 * @returns Claim | undefined
 */
export function useOrderItemClaim(inventoryItem: InventoryItem | null) {
  const order = useOrder();
  return useMemo(() => {
    const claims = order.claims;
    if (claims) {
      return claims.find(
        (claim) =>
          claim.lotNumber === inventoryItem?.lotNumber &&
          claim.tagNumber === inventoryItem.tagNumber
      );
    }
  }, [order, inventoryItem]);
}
