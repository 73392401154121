import classnames from "classnames";
import { HighlightUnbackedData } from "components/common/HighlightUnbackedData";
import { formatNumber } from "tools/formatNumber";
import scss from "../Belongings.module.scss";

interface EstimatedWeightProps {
  estimatedWeight: number;
}

export function EstimatedWeight({ estimatedWeight }: EstimatedWeightProps) {
  return (
    <div className={classnames(scss.estimated_weight)} tabIndex={0}>
      <div className={classnames(scss.label)}>Estimated Weight</div>
      <div data-testid="Estimated Weight">
        <span className={classnames(scss.amount)} data-testid="Amount">
          <HighlightUnbackedData>
            {formatNumber(estimatedWeight)}
          </HighlightUnbackedData>
        </span>
        <span className={classnames(scss.measure)}>lbs</span>
      </div>
    </div>
  );
}
