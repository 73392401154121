import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Card } from "components/common";
import { useSurvey } from "../tools/surveyAtom";

type WeightRowProps = {
  name: string;
  weight: number;
};

const WeightTableRow = ({ name, weight }: WeightRowProps) => (
  <TableRow key={name}>
    <TableCell
      component="th"
      scope="row"
      sx={{ color: "inherit", borderBottom: "none", padding: "8px" }}
    >
      <Typography variant="mHeading">{name}</Typography>
    </TableCell>
    <TableCell align="right" sx={{ borderBottom: "none", padding: "8px" }}>
      <Typography variant="lHeading" color={"#0069a0"}>
        {weight !== null ? `${weight} lbs` : "--"}
      </Typography>
    </TableCell>
  </TableRow>
);

export const ProgearWeightsCard = () => {
  const survey = useSurvey();
  if (!survey) {
    return null;
  }

  const { estimatedProGearWeight, estimatedSpouseProGearWeight } = survey;

  const gunSafeWeight = survey.itemsGunSafe.reduce((acc, item) => {
    acc += item.going * Number(item.weight);
    return acc;
  }, 0);

  if (
    !estimatedProGearWeight &&
    !gunSafeWeight &&
    !estimatedSpouseProGearWeight
  ) {
    return null;
  }

  const weightsData: WeightRowProps[] = [
    { name: "Progear Weight", weight: estimatedProGearWeight },
    { name: "Spouse Progear Weight", weight: estimatedSpouseProGearWeight },
    { name: "Gun Safe Weight", weight: gunSafeWeight },
  ];

  return (
    <Card title="Weights">
      <Table>
        <TableBody>
          {weightsData.map((data) => (
            <WeightTableRow key={data.name} {...data} />
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};
