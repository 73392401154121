import { Box, Stack, Typography } from "@mui/material";
import { Button, Modal, ModalButtons } from "components/common/ui";
import { InventoryItemTitle } from "components/pages/ClaimDetailsPage/ClaimDetailsSummary/InventoryItemTitle";
import { InventoryItem } from "models";
import { InfoLabel, InfoValue } from "components/common/ui/InfoGrid";
import { formatTime } from "@movehq/datetime";
import { InfoBoxNew } from "components/common/InfoBox";
import { FormattedMessage } from "services";

interface Props {
  item?: InventoryItem;
  onClose(): void;
  onCreateClaim(): void;
  onGotoClaim?(): void;
}

const noticeTypeDisplay = (type: "MISSING" | "DAMAGED") => {
  switch (type) {
    case "DAMAGED":
      return "Damaged";
    case "MISSING":
      return "Missing";
  }
};

export const NoticeModal: React.FC<Props> = ({
  item,
  onClose,
  onCreateClaim,
  onGotoClaim,
}) => {
  return (
    <Modal
      open={item !== undefined}
      onClose={onClose}
      title={`Notice Filed`}
      width={600}
    >
      {item && item.lossOrDamageNotice ? (
        <div>
          <InventoryItemTitle item={item} />
          <Box mb="16px">
            <Typography variant="xsHeading">
              {noticeTypeDisplay(item.lossOrDamageNotice?.noticeType!)}
            </Typography>
            {item.lossOrDamageNotice?.description ? (
              <Typography variant="xsBody">
                {" "}
                - {item.lossOrDamageNotice.description}
              </Typography>
            ) : null}
          </Box>
          <Box mb="24px">
            <Stack direction="row" spacing="24px">
              <Box>
                <InfoLabel>Reported at</InfoLabel>
                <InfoValue>
                  {formatTime(item.lossOrDamageNotice.reportedAt, "dateAtTime")}
                </InfoValue>
              </Box>
              <Box>
                <InfoLabel>Reported by</InfoLabel>
                <InfoValue>{item.lossOrDamageNotice?.createdBy}</InfoValue>
              </Box>
            </Stack>
          </Box>
          <InfoBoxNew
            content={
              <FormattedMessage id="createClaim.noticeModal.disclaimer" />
            }
          ></InfoBoxNew>

          <ModalButtons>
            <Button variant="secondary" onClick={() => onClose()}>
              Close
            </Button>
            {onGotoClaim ? (
              <Button onClick={() => onGotoClaim()}>View claim</Button>
            ) : (
              <Button onClick={() => onCreateClaim()}>File claim</Button>
            )}
          </ModalButtons>
        </div>
      ) : null}
    </Modal>
  );
};
