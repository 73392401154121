import styled from "@emotion/styled";

import { InventoryItem, getImage } from "../../../../models";
import { Thumbnail } from "components/common/ui/Thumbnail";
import { Typography } from "@mui/material";

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 15px;
`;

export const InventoryItemTitle = ({ item }: { item: InventoryItem }) => {
  const imgSrc = getImage(item);
  const { lotNumber, tagNumber, tagColor, itemName } = item;
  return (
    <Wrapper>
      <Thumbnail src={imgSrc} />
      <Typography variant="sHeading">{`${lotNumber}-${tagNumber}-${tagColor}: ${itemName}`}</Typography>
    </Wrapper>
  );
};
