import {
  Stack,
  Typography,
  useMediaQuery,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Gauge } from "components/common/ui/Gauge";
import { formatNumber } from "tools/formatNumber";
import { useSurveyCalculations } from "hooks/useSurveyCalculations";
import { useOrder } from "services/orders/OrderGuard";
import { FormattedMessage } from "services/translation/FormattedMessage";

export function MiniWeightWizard() {
  const hideOnXxs = useMediaQuery("(max-width:400px)");
  const order = useOrder();
  const { estimatedWeight, estimatedWeightPercentage } =
    useSurveyCalculations();

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        width={1}
        gap={2}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          width={60}
          height={1}
        >
          <Gauge value={estimatedWeightPercentage} />
        </Stack>

        <Stack>
          <Typography
            variant="xsHeading"
            fontWeight={600}
            whiteSpace="nowrap"
            color="primary.main"
            marginBottom="-8px"
          >
            Estimated weight
          </Typography>
          <Stack direction="row" alignItems="end">
            <Typography
              sx={{ typography: { xs: "xxlHeading", sm: "xxxlHeading" } }}
              component="span"
              fontWeight={600}
              color="primary.main"
            >
              {formatNumber(estimatedWeight)}
            </Typography>
            <Typography
              variant="xxsHeading"
              component="span"
              fontWeight={600}
              lineHeight="1.6rem"
            >
              lbs
            </Typography>
          </Stack>
        </Stack>

        <Stack display={hideOnXxs ? "none" : "flex"}>
          <Typography
            variant="xsHeading"
            fontWeight={600}
            whiteSpace="nowrap"
            color="#808080"
            marginBottom="-8px"
          >
            Entitlement Allowance
          </Typography>
          <Stack direction="row" alignItems="end">
            <Typography
              sx={{ typography: { xs: "xxlHeading", sm: "xxxlHeading" } }}
              component="span"
              fontWeight={600}
              color="#808080"
            >
              {formatNumber(order?.entitlement?.totalWeight)}
            </Typography>
            <Typography
              variant="xxsHeading"
              component="span"
              fontWeight={600}
              lineHeight="1.6rem"
              color="#808080"
            >
              lbs
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {/* @ts-ignore see theme-overrides */}
      <Accordion defaultExpanded variant="borderless">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Disclaimers</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb={1}>
            <Typography variant="xxsBody" color="error">
              *{" "}
            </Typography>
            <Typography variant="xxsBody">
              <FormattedMessage id="gauge.disclaimer" />
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="xxsBody" color="error">
              *{" "}
            </Typography>
            <Typography variant="xxsBody">
              <FormattedMessage id="gauge.disclaimer2" />
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
