import { Model, Attr } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";

@Model()
export class VirtualSurveyCredentials extends ApplicationRecord {
  static jsonapiType = "virtual_survey_credentials";
  @Attr() serviceId!: string;
  @Attr() apiKey!: string;
  @Attr() token!: string;
  @Attr() sessionId!: string;
}
