import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridComparatorFn } from "@mui/x-data-grid";
import { Button, Input } from "components/common/ui";
import Fuse from "fuse.js";
import { InventoryItem, InventoryRoom, CargoClaimDetail } from "models";
import Link from "next/link";
import React, { useMemo, useState } from "react";
import { theme } from "../../../../../../../../styles";
import { InfoPopover } from "components/common/ui/InfoPopover";
import { NoticeModal } from "../NoticeModal";
import { useRouter } from "next/navigation";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FormattedMessage } from "services";

interface InventoryTableProps {
  items: InventoryItem[];
}

interface ClaimDropdownProps {
  claimDetails: CargoClaimDetail[];
}

interface Row {
  id: string;
  item: InventoryItem;
  itemNumber: string;
  itemName: string;
  roomName: string;
  image: string;
  hasNotice: boolean;
}

const StyledDataGrid = styled(DataGrid<Row>)`
  border: 0;
  font-family: ${({ theme }) => `${theme.typography.fontFamily}`};
  color: ${({ theme }) => `${theme.palette.primary.dark}`};
  font-size: ${({ theme }) => `${theme.typography.sBody.fontSize}`};

  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within,
  .MuiDataGrid-columnHeader:focus {
    outline: none;
  }

  & .MuiDataGrid-iconSeparator {
    display: none;
  }

  & .MuiDataGrid-columnHeaderTitle {
    color: ${({ theme }) => `${theme.palette.info.dark}`};
    font-size: 12px;
    font-weight: 600;
  }

  & .roomNameCell {
    font-size: 0.75rem;
    font-weight: 600;
    justify-content: flex-start;
  }

  & .MuiDataGrid-footerContainer {
    justify-content: unset;
    width: 100%;
  }

  & .MuiTablePagination-root {
    width: 100%;
  }

  & .MuiInputBase-root {
    width: auto;
  }
`;

const InputWrapper = styled(Box)`
  input {
    padding-left: 50px;
  }
  svg {
    color: #888;
  }
`;

function ClaimsDropdown({ claimDetails }: ClaimDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const claimLinks = claimDetails.map((claimDetail) => {
    const claim = claimDetail.claim;
    return (
      <MenuItem key={claimDetail?.id}>
        <Link href={claim?.claimDetailsUrl}>Claim No. {claim?.id}</Link>
      </MenuItem>
    );
  });

  return (
    <Box sx={{ width: "90px" }}>
      <IconButton onClick={handleClick}>
        <Typography variant="sBody">Claims({claimDetails.length})</Typography>
        <ArrowDropDownOutlinedIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {claimLinks}
      </Menu>
    </Box>
  );
}

const claimableComparator: GridComparatorFn = (
  a: InventoryItem,
  b: InventoryItem
) => {
  const aClaimable = a.claimable;
  const bClaimable = b.claimable;

  if (aClaimable === bClaimable) {
    return 0;
  }
  if (aClaimable) {
    return -1;
  }
  return 1;
};

export function InventoryTable(props: InventoryTableProps) {
  const { items } = props;
  const router = useRouter();
  const [isRouting] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const shouldDisplayImages = window.innerWidth >= theme.breakpoints.values.sm;
  const [search, setSearch] = useState("");
  const filteredItems = useMemo(() => {
    const formattedSearch = search.trim();
    if (formattedSearch.length < 2) {
      return items;
    }
    const fuse = new Fuse(items, {
      includeScore: false,
      keys: ["itemName", "inventoryRoom.roomName", "itemNumberDisplay"],
      threshold: 0.3,
      ignoreLocation: true,
    });
    const result = fuse.search(formattedSearch);
    return result.map((item) => item.item);
  }, [search]);

  const selectedItem = useMemo(() => {
    return selectedItemId
      ? items.find((item) => item.id === selectedItemId)
      : undefined;
  }, [items, selectedItemId]);

  const handleFileNotice = (itemId: string) => {
    return router.push("/claim/create/notice?itemId=" + itemId);
  };

  const handleFileClaim = (itemId: string) => {
    return router.push("/claim/create/cargo?itemId=" + itemId);
  };

  const columns = useMemo(() => {
    const columns: GridColDef<Row>[] = [
      {
        field: "image",
        headerName: "",
        maxWidth: 52,
        renderCell: (params) => <img src={params.value} width="100%" />,
      },
      {
        field: "itemNumber",
        headerName: "Item No.",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => <>{params.row.item.itemNumberDisplay}</>,
      },
      { field: "itemName", headerName: "Name", flex: 1 },
      {
        field: "roomName",
        headerName: "Room",
        flex: 1,
        cellClassName: "roomNameCell",
      },
      {
        field: "hasNotice",
        headerName: "File Notice",
        resizable: false,
        sortable: false,
        disableColumnMenu: true,
        filterable: false,
        minWidth: 130,
        width: 130,
        renderHeader: (params) => {
          return (
            <Box display="flex" alignItems="center">
              <Typography variant="xsHeading" color="#666">
                Notice
              </Typography>
              <Box height="44px" ml="4px">
                <InfoPopover
                  contentMessageId="inventory.table.extensionpopover.content"
                  titleMessageId="inventory.table.extensionpopover.title"
                />
              </Box>
            </Box>
          );
        },
        renderCell: (params) => {
          // If there is a claim but no notice, don't show the notice option
          if (
            params.row.item.claimDetails.length > 0 &&
            !params.row.hasNotice
          ) {
            return <>--</>;
          }

          // If there is an existing notice, show
          // a link which will open a modal to view it
          if (params.row.hasNotice) {
            return (
              <Typography
                variant="sBody"
                component="a"
                onClick={() => setSelectedItemId(params.row.id)}
              >
                View notice
              </Typography>
            );
          }

          const claimable = params.row.item.claimable;

          // Otherwise show a button to file a notice
          return (
            <Tooltip
              title={
                !claimable ? (
                  <FormattedMessage id="inventory.table.unnoticabletooltip.title" />
                ) : undefined
              }
            >
              <Box width={"100%"}>
                <Button
                  size="medium"
                  variant="secondary"
                  aria-label={"button"}
                  disabled={!claimable}
                  onClick={() => {
                    handleFileNotice(params.row.id);
                  }}
                >
                  File Notice
                </Button>
              </Box>
            </Tooltip>
          );
        },
      },
      {
        field: "item",
        headerName: "File claim",
        resizable: false,
        sortable: true,
        sortComparator: claimableComparator,
        disableColumnMenu: true,
        filterable: false,
        width: 130,
        renderHeader: () => {
          return (
            <Box display="flex" alignItems="center">
              <Typography variant="xsHeading" color="#666">
                Claim
              </Typography>
              <Box height="44px" ml="4px">
                <InfoPopover
                  contentMessageId="inventory.table.claimpopover.content"
                  titleMessageId="inventory.table.claimpopover.title"
                />
              </Box>
            </Box>
          );
        },
        renderCell: (params) => {
          const item = params.row.item;
          const claimable = params.row.item.claimable;
          if (item.claimDetails.length > 0) {
            return (
              <>
                <ClaimsDropdown claimDetails={item.claimDetails} />
                {claimable && (
                  <IconButton
                    onClick={() => handleFileClaim(params.row.id)}
                    sx={{ paddingLeft: "0.5rem" }}
                  >
                    <AddCircleOutlineOutlinedIcon />
                  </IconButton>
                )}
              </>
            );
          } else {
            return (
              <Tooltip
                title={
                  !claimable ? (
                    <FormattedMessage id="inventory.table.unclaimabletooltip.title" />
                  ) : undefined
                }
              >
                <Box width={"100%"}>
                  <Button
                    size="medium"
                    variant="secondary"
                    aria-label={"button"}
                    disabled={!claimable}
                    onClick={() => {
                      handleFileClaim(params.row.id);
                    }}
                  >
                    File Claim
                  </Button>
                </Box>
              </Tooltip>
            );
          }
        },
      },
    ];

    return columns;
  }, [router, isRouting]);
  const rows: Row[] = filteredItems.map((item, index) => {
    const inventoryRoom: InventoryRoom = item.relationships
      .inventoryRoom as InventoryRoom;
    return {
      id: item.id!,
      itemNumber: item.tagNumber,
      itemName: item.itemName,
      roomName: inventoryRoom?.roomName ?? "--",
      item: item,
      image: item.images[0] || "/images/inventory/default.svg",
      hasNotice: Boolean(item.lossOrDamageNotice),
    };
  });

  return (
    <>
      <div>
        <InputWrapper mt="48px" mb="32px" position="relative">
          <Input
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder="What item are you looking for?"
          />
          <Box position="absolute" top={16} left={14}>
            <SearchIcon />
          </Box>
        </InputWrapper>
        <StyledDataGrid
          autoHeight
          columns={columns}
          rows={rows}
          initialState={{
            columns: {
              columnVisibilityModel: {
                image: shouldDisplayImages,
              },
            },
            sorting: {
              sortModel: [
                {
                  field: "item",
                  sort: "asc",
                },
              ],
            },
          }}
          disableRowSelectionOnClick
          disableColumnSelector
        />
        <NoticeModal
          item={selectedItem}
          onClose={() => setSelectedItemId(null)}
          onCreateClaim={() => {
            if (selectedItem?.id) {
              handleFileClaim(selectedItem?.id);
            }
          }}
          onGotoClaim={
            selectedItem?.claimDetails && selectedItem.claimDetails.length > 0
              ? () => {
                  router.push(
                    selectedItem.claimDetails[0].claim.claimDetailsUrl
                  );
                }
              : undefined
          }
        />
      </div>
    </>
  );
}
