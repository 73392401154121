import { ReactNode } from "react";
import { useToggle } from "react-use";
import { Modal as MuiModal, Paper as MuiPaper } from "@mui/material";
import styled from "@emotion/styled";

interface ModalProps {
  children: ReactNode;
  opts: {
    title: string;
    description?: string;
    displayTitle?: boolean;
    className?: string;
  };
}

const StyledModal = styled(MuiModal)`
  display: grid;
`;

const StyledPaper = styled(MuiPaper)`
  place-self: center;
  padding: 0 1em;
  position: relative;
`;

const CloseButton = styled.button({
  position: "absolute",
  background: "none",
  padding: 0,
  fontSize: "1.25rem",
  height: "1.5rem",
  width: "1.5rem",
  border: "none",
  color: "#0069a0",
  top: "1.2rem",
  right: "0.5rem",
  cursor: "pointer",
});

/**
 * Custom hook which returns a Modal component (and toggleModal callback) that match the old
 * API present in the old customer-ui.
 *
 * This was created as a way to more easily port over old code that relies on the old <Modal /> component (with its unique props/usage).
 *
 * This is *not necessarily* intended to be used going forward.
 *
 * Instead you should rely on MUI's <Modal /> directly.
 *
 * See: https://mui.com/material-ui/react-modal/#main-content
 * @returns Modal, toggleModal
 */

export function useModal(onClose?: () => void) {
  const [modalIsOpen, setModalIsOpen] = useToggle(false);
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setModalIsOpen(false);
  };

  function toggleModal(modalIsOpen?: boolean) {
    if (typeof modalIsOpen === "undefined") {
      setModalIsOpen(!modalIsOpen);
    } else {
      setModalIsOpen(modalIsOpen);
    }
  }

  function Modal(props: ModalProps) {
    const {
      children,
      opts: { title, description, displayTitle, className },
    } = props;
    return (
      <StyledModal
        open={modalIsOpen}
        onClose={handleClose}
        aria-labelledby={title}
        aria-label={title}
        aria-describedby={description}
        style={{ overflowY: "scroll" }}
      >
        <StyledPaper className={className} data-testid="modal">
          <CloseButton
            onClick={() => {
              if (onClose) {
                onClose();
              }
              toggleModal(false);
            }}
          >
            x
          </CloseButton>
          {displayTitle && <h3>{title}</h3>}
          {children}
        </StyledPaper>
      </StyledModal>
    );
  }

  return {
    /**
     * Modal component whos API matches the old customer-ui bespoke <Modal /> component
     * @param props
     * @returns JSX.Element
     */
    Modal,
    /**
     * Flag indicating the current modal state
     */
    modalIsOpen,
    /**
     * Function which:
     * * Toggles the modal open state (if `modalIsOpen` state is **NOT provided**)
     * * Sets the modal to a desired state (if a `modalIsOpen` state **IS provided**)
     * @param modalIsOpen
     */
    toggleModal,
  };
}
