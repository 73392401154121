import { useMemo } from "react";
import { InventoryItem, OrderRoom } from "models";
import { useOrder } from "services/orders/OrderGuard";

/**
 * Lookup and return an inventory item from the order using its itemId
 * @param itemId
 * @returns
 */
export function useInventoryItem(itemId?: string) {
  const rooms = useOrder().inventoryRooms;

  return useMemo(() => {
    function reduceItems(acc: InventoryItem | null, item: InventoryItem) {
      if (item.id?.toString() === itemId?.toString()) {
        return item;
      }
      return acc;
    }

    function findInventoryItem(items: Array<InventoryItem>) {
      return items.reduce<null | InventoryItem>(reduceItems, null);
    }

    return rooms.reduce<{
      item: InventoryItem | null;
      room: OrderRoom | null;
    }>(
      (acc, room) => {
        const item = findInventoryItem(room.items);
        if (item) {
          return { item, room };
        }
        return acc;
      },
      { item: null, room: null }
    );
  }, [rooms, itemId]);
}
