import { SyntheticEvent, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import {
  useChangedItemsAtom,
  useSurveyItem,
  useChangedItemQuantityAtom,
} from "../tools/surveyAtom";
import { useIntl } from "services/translation";
import {
  CardContent,
  List,
  ListItem,
  Paper,
  Popover,
  Typography,
} from "@mui/material";
import { ShipmentType } from "types";
import { HeavyIcon } from "./HeavyIcon";
import { useIsItemHeavy } from "../tools/hooks";

interface ChangedListItem {
  roomId: string;
  itemId: string;
  shipmentType: ShipmentType;
  quantity: number;
}
type ChangedList = Array<ChangedListItem>;

const useChangedItemsReviewList = () => {
  const [{ changedItems }] = useChangedItemsAtom();
  const [list, setList] = useState<ChangedList>([]);

  useEffect(() => {
    const newList = Object.keys(changedItems).reduce<ChangedList>(
      (list, key) => {
        const [shipmentType, roomId, itemId] = key.split(":");
        const quantity = changedItems[key];

        if (quantity === 0) {
          return list;
        } else {
          return [
            ...list,
            {
              shipmentType: shipmentType as ShipmentType,
              roomId,
              itemId,
              quantity,
            },
          ];
        }
      },
      []
    );
    setList(newList);
  }, [changedItems]);

  return list;
};

function ChangedListItem({
  roomId,
  itemId,
  shipmentType,
}: {
  roomId: string;
  itemId: string;
  shipmentType: ShipmentType;
}) {
  const { formatMessage } = useIntl();
  const item = useSurveyItem(itemId);
  const [changedQuantity, setChangedQuantity] = useChangedItemQuantityAtom(
    roomId,
    itemId,
    shipmentType
  );

  const reset = () => setChangedQuantity(0);
  const isHeavy = useIsItemHeavy(item);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr 1fr 1fr",
        alignItems: "center",
        width: 1,
        textAlign: "center",
      }}
    >
      <Typography variant="sBody" textAlign="left" color="primary.main">
        {item?.name}
      </Typography>
      <Typography variant="sBody">{isHeavy && <HeavyIcon />}</Typography>
      <Typography variant="sBody">{changedQuantity}</Typography>
      <Button
        aria-label={formatMessage({ id: "ariaLabels.restoreItemButton" })}
        variant="text"
        onClick={reset}
      >
        {formatMessage({ id: "reviewYourBelongings.restore" })}
      </Button>
    </Box>
  );
}

export function ChangedItemsPopover({
  anchorEl,
  onClose,
}: {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
}) {
  const { formatMessage } = useIntl();
  const changedItemsList = useChangedItemsReviewList();
  const open = Boolean(anchorEl);

  return (
    <Popover
      id="belongings-review-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={(e: SyntheticEvent) => {
        // We only want this popover to listen click outside
        e.stopPropagation();
        onClose();
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{ maxHeight: { xs: "80vh", sm: "70vh" } }}
    >
      <Paper sx={{ width: "540px", maxWidth: 1 }}>
        <CardContent sx={{ p: 4, pt: 5 }}>
          <List>
            <ListItem divider sx={{ px: 0 }}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "2fr 1fr 1fr 1fr",
                  width: 1,
                  color: "#808080",
                  textAlign: "center",
                }}
              >
                <Typography variant="sBody" textAlign="left">
                  {formatMessage({ id: "reviewYourBelongings.itemName" })}
                </Typography>
                <Typography variant="sBody">
                  {formatMessage({ id: "reviewYourBelongings.heavy" })}
                </Typography>
                <Typography variant="sBody">
                  {formatMessage({ id: "reviewYourBelongings.quantity" })}
                </Typography>
                <Typography variant="sBody">
                  {formatMessage({ id: "reviewYourBelongings.restore" })}
                </Typography>
              </Box>
            </ListItem>
            {changedItemsList?.map(({ shipmentType, roomId, itemId }) => (
              <ListItem
                key={`changed-${shipmentType}-${roomId}-${itemId}`}
                divider
                sx={{ px: 0 }}
              >
                <ChangedListItem
                  roomId={roomId}
                  itemId={itemId}
                  shipmentType={shipmentType}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Paper>
    </Popover>
  );
}
