import { Stack, Typography } from "@mui/material";
import { useIntl, FormattedMessage } from "services/translation";
import { useMuiPopupState } from "hooks/useMuiPopupState";
import { useTotalChangedItems } from "../tools/surveyAtom";
import { ChangedItemsPopover } from "./ChangedItemsPopover";

export function ChangedItemsTag() {
  const { formatMessage } = useIntl();
  const totalChangedItems = useTotalChangedItems();
  const popupState = useMuiPopupState();

  const linkMessage = popupState.isPopupOpen
    ? "reviewYourBelongings.changedViewLinkClose"
    : "reviewYourBelongings.changedViewLink";

  return (
    <Stack
      component="button"
      direction="row"
      alignItems="center"
      gap={3}
      onClick={popupState.openPopup}
      sx={{
        px: 2,
        py: 1,
        borderRadius: "8px",
        backgroundColor: "colors.warningOrange",
        borderColor: "colors.orange",
        borderWidth: 1,
        borderStyle: "solid",
        lineHeight: "22px",
        "&:hover": {
          cursor: "pointer",
        },
      }}
      aria-label={formatMessage({
        id: "ariaLabels.changedItemsAlert",
      })}
    >
      <Typography
        variant="sHeading"
        fontWeight={600}
        color="primary.main"
        aria-label={formatMessage({
          id: "ariaLabels.removedAlertMessage",
        })}
        sx={{
          display: {
            xs: popupState.isPopupOpen ? "none" : "block",
            sm: "block",
          },
        }}
      >
        <FormattedMessage
          id="reviewYourBelongings.changedAlertQuantity"
          values={{ quantity: totalChangedItems }}
        />
      </Typography>
      <Typography
        variant="sHeading"
        fontWeight={600}
        color="#0069A0"
        padding={0}
        sx={{
          display: {
            xs: popupState.isPopupOpen ? "block" : "none",
            sm: "block",
          },
          textDecoration: "underline",
        }}
        aria-label={formatMessage({
          id: "ariaLabels.removedItemsLink",
        })}
      >
        <FormattedMessage id={linkMessage} />
      </Typography>

      <ChangedItemsPopover
        anchorEl={popupState.popupAnchorEl}
        onClose={popupState.closePopup}
      />
    </Stack>
  );
}
