import useSWR from "swr";
import { depotHost } from "constant/apiEndpoints";
import { postFetcher, getFetcher } from "tools/postFetcher";
import { useSurvey } from "components/pages/BelongingsPage/tools/surveyAtom";
import { useEffect, useState } from "react";
import { atom, useAtom } from "jotai";
import { loadable } from "jotai/utils";

export function useEstimatedOverageCost({
  hasOverage,
  totalWeight,
}: {
  hasOverage: boolean;
  totalWeight: number;
}) {
  const survey = useSurvey();
  const url = `${depotHost}/api/v1/surveys/${survey?.id}/estimated_overage_cost`;
  return useSWR(
    survey?.id &&
      hasOverage && [
        url,
        {
          body: {
            total_weight: totalWeight,
          },
        },
      ],
    postFetcher
  );
}

export interface EstimatedOverageCostResult {
  /**
   *  The cost given max weight and original address
   */
  allowance_cost: number;
  /**
   * The cost given survey weight (or submitted weight) and original address
   */
  survey_cost: number;
  /**
   * Total cost, taking into account change of address (and anything else)
   */
  total_cost: number;
  /**
   * Total - allowance
   */
  excess_cost: number;
  /**
   * If there was an error calculating
   */
  error?: string;
}

export function useEstimatedMoveCost(params?: { totalWeight: number }) {
  const survey = useSurvey();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<EstimatedOverageCostResult | null>(null);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const weight = params?.totalWeight;
    const load = async () => {
      try {
        setIsLoading(true);
        const url = `${depotHost}/api/v1/surveys/${survey?.id}/cost_estimate${
          weight ? `?weight=${weight}` : ""
        }`;
        const result = await getFetcher<EstimatedOverageCostResult>([url, {}]);
        if (result.error) {
          throw new Error(result.error);
        }
        setError(null);
        setData(result);
        setIsLoading(false);
        return result;
      } catch (err) {
        setError(err);
        setData(null);
        setIsLoading(false);
      }
    };

    load();
  }, [params?.totalWeight]);

  return {
    data,
    isLoading,
    error,
  };
}
