import { VirtualSurveyCredentials } from "models/VirtualSurveyCredentials";
import { useCallback } from "react";

export const useVideoCredentials = () => {
  const get = useCallback(async (serviceId: string) => {
    const creds = new VirtualSurveyCredentials({
      serviceId,
    });
    await creds.save();
    return creds;
  }, []);
  return get;
};
