import { Fetcher } from "swr";
import { ApplicationRecord } from "models";

export type PostFetcher = Fetcher<
  Record<string, any>,
  [string, Parameters<typeof fetch>[1]]
>;
export const postFetcher = async <TBody>([url, options]: [
  string,
  { body: TBody; headers?: HeadersInit }
]) => {
  return fetch(url, {
    ...options,
    body: JSON.stringify(options?.body),
    headers: {
      ...options?.headers,
      "Content-Type": "application/json",
      Authorization: ApplicationRecord.authHeader,
    },
    method: "POST",
  }).then((res) => res.json());
};

export const getFetcher = async <TResult>([url, options]: [
  string,
  { headers?: HeadersInit }
]): Promise<TResult> => {
  return fetch(url, {
    ...options,
    headers: {
      ...options?.headers,
      "Content-Type": "application/json",
      Authorization: ApplicationRecord.authHeader,
    },
    method: "GET",
  }).then((res) => res.json());
};
