import { HTMLAttributes, ReactNode } from "react";

interface TabPanelProps<T = number | string>
  extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  index: T;
  value: T;
}

export function TabPanel<T>(props: TabPanelProps<T>) {
  const { children, value, index } = props;
  return <>{value === index && children}</>;
}
