import { getEnv } from "tools";

export const depotHost = getEnv("NEXT_PUBLIC_DEPOT_HOST");

/**
 * Endpoint to get the list of orders for the current user
 */
export const ordersEndpoint = `${depotHost}/api/v1/orders`;
export const orderEndpoint = `${ordersEndpoint}/:orderId`;

/**
 * Endpoint to post a claims form for a specified orderId
 */
export const claimsEndpoint = `${depotHost}/api/v1/orders/:orderId/claims`;

/**
 * Endpoint to get crew information for a specified service
 */
export const servicesEndpoint = `${depotHost}/api/v1/services`;
export const serviceEndpoint = `${servicesEndpoint}/:serviceId`;

export const apiEndpoints = {
  orders: {
    index: ordersEndpoint,
    show: ordersEndpoint,
  },
  move_types: {
    index: `${depotHost}/api/v1/move_types`,
    show: `${depotHost}/api/v1/move_types/:orderId`,
    update: `${depotHost}/api/v1/move_types/:orderId`,
  },
  change_requests: {
    index: `${depotHost}/api/v1/change_requests`,
    create: `${depotHost}/api/v1/change_requests`,
    show: `${depotHost}/api/v1/change_requests/:orderId`,
    update: `${depotHost}/api/v1/change_requests/:orderId`,
  },
  services: {
    index: servicesEndpoint,
    show: serviceEndpoint,
  },
  survey_availabilties: {
    index: `${depotHost}/api/v1/survey_availabilities`,
  },
  survey_appointments: {
    index: `${depotHost}/api/v1/survey_appointments`,
  },
  virtual_survey_credentials: {
    index: `${depotHost}/api/v1/virtual_survey_credentials`,
  },
};
