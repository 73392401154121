import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { FormattedMessage, useIntl } from "services/translation";
import { useNotify } from "services/notification";
import { useTotalChangedItems } from "../tools/surveyAtom";
import { debug } from "tools/debug";
export function ConfirmSurveyChangesDialog({
  open,
  onCancel,
  onConfirm,
}: {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
}) {
  const [loading, setLoading] = useState(false);
  const totalChangedItems = useTotalChangedItems();
  const { formatMessage } = useIntl();
  const notify = useNotify();

  return (
    <Dialog open={open}>
      <CardContent>
        <DialogTitle>
          <FormattedMessage
            id="reviewYourBelongings.confirmChanges.title"
            values={{ total: totalChangedItems }}
          />
        </DialogTitle>

        <DialogContent>
          <FormattedMessage
            id="reviewYourBelongings.confirmChanges.message"
            values={{ total: totalChangedItems }}
          />
        </DialogContent>

        {
          <DialogActions>
            <Button disabled={loading} variant="outlined" onClick={onCancel}>
              <FormattedMessage id="reviewYourBelongings.confirmChanges.cancelButton" />
            </Button>
            <Button
              variant="contained"
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                try {
                  await onConfirm();
                  notify(
                    formatMessage({ id: "reviewYourBelongings.saveSuccess" }),
                    {
                      variant: "success",
                    }
                  );
                } catch (e) {
                  debug("Error saving survey", e);
                  notify(
                    formatMessage({ id: "reviewYourBelongings.saveError" }),
                    {
                      variant: "error",
                    }
                  );
                  throw e;
                } finally {
                  setLoading(false);
                }
              }}
            >
              {loading ? (
                <CircularProgress sx={{ margin: "3px" }} size="16px" />
              ) : (
                <FormattedMessage id="reviewYourBelongings.confirmChanges.confirmButton" />
              )}
            </Button>
          </DialogActions>
        }
      </CardContent>
    </Dialog>
  );
}
