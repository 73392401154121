import { Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { theme } from "styles";

export const InfoLabel: React.FC<PropsWithChildren> = ({ children }) => (
  <Typography
    component="h4"
    variant="xxsHeading"
    color={theme.palette.colors.grey30}
  >
    {children}
  </Typography>
);

export const InfoValue: React.FC<PropsWithChildren> = ({ children }) => (
  <Typography variant="sBody" color={theme.palette.secondary.main}>
    {children}
  </Typography>
);
