import { useEffect, useState, useCallback } from "react";
import { SurveyAvailability } from "models";
import { stringToPlainDate, Temporal } from "@movehq/datetime";
import { useOrder } from "store";
import { datadogRum } from "@datadog/browser-rum";

type DateMap = {
  [s: string]: Array<{
    id: string;
    startTime: Temporal.PlainTime;
    endTime: Temporal.PlainTime;
  }>;
};

/**
 * Requests availabilities from depot, and formats / filters the
 * data so it is consumable in the schedule survey flow.
 *
 * Resulting shape of availabilities looks like:
 *
 * ```
 * {
 *   '2023-01-01': [
 *     {
 *       startTime: Date(),
 *       endTime: Date(),
 *       id: "11223344"
 *     },
 *     {
 *       startTime: Date(),
 *       endTime: Date(),
 *       id: "22334455"
 *     },
 *     ...
 *   ],
 *   '2023-01-02': [...],
 * }
 * ```
 */
export const useSurveyAvailabilities = () => {
  const [dateMap, setDateMap] = useState<DateMap | null>(null);
  const { id: orderId } = useOrder();
  const [timezone, setTimezone] = useState<string | undefined>(undefined);

  const get = useCallback(async () => {
    const startDate = Temporal.Now.plainDate(Temporal.Calendar.from("gregory"));
    const endDate = startDate.add({ days: 30 });
    const { data } = await SurveyAvailability.per(1000)
      .where({
        start_date: startDate,
        end_date: endDate,
        // For now hardcode virtual since it's the only option
        survey_type: "virtual",
        order_id: orderId,
      })
      .all();

    if (!data || !data.length) {
      datadogRum.addError("No survey availabilities found");
    }
    setTimezone(data[0].timezone);

    const dateMap = data.reduce((acc, curr) => {
      if (curr.availableSurveyors > 0) {
        const startTime = Temporal.PlainTime.from(curr.startDatetime);
        const endTime = Temporal.PlainTime.from(curr.endDatetime);
        const day = stringToPlainDate(curr.startDatetime).toString();

        if (acc[day] === undefined) {
          acc[day] = [];
        }

        acc[day].push({
          startTime,
          endTime,
          id: curr.id,
        });
      }
      return acc;
    }, {} as DateMap);

    setDateMap(dateMap);
  }, []);

  useEffect(() => {
    get();
  }, [get]);

  return {
    availabilities: dateMap,
    refresh: get,
    timezone,
  };
};
