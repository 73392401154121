"use client";
import { useState } from "react";
import { FormattedMessage } from "services";
import { BackNav } from "components/common/ui/BackNav";
import {
  WeightWizard,
  ChangedItemsTag,
  ConfirmSurveyChangesDialog,
  MiniWeightWizard,
  SurveyItemSummary,
} from "./components";
import {
  useSurvey,
  useTotalChangedItems,
  useChangedItemsAtom,
} from "./tools/surveyAtom";
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { MessageBar } from "./components/MessageBar";
import { buildNewSurvey } from "./tools/buildNewSurvey";
import { debug } from "tools";
import { useRefreshOrder } from "services/orders";
import { useRouter } from "next/navigation";
import { H1, P } from "components/common/Typography/typography";
import Link from "next/link";
import { PageWrapper } from "components/common/PageWrapper";
import { useWindowScroll } from "react-use";

export function BelongingsPage() {
  const survey = useSurvey();
  const router = useRouter();
  const [isConfirmChangesOpen, setIsConfirmChangesOpen] = useState(false);
  const totalChangedItems = useTotalChangedItems();
  const [{ changedItems }] = useChangedItemsAtom();
  const hasChangedItems = totalChangedItems > 0;
  const { refresh: refreshOrder } = useRefreshOrder();

  const hasPendingApproval = survey?.isPendingApproval;

  const isUneditable = Boolean(hasPendingApproval);

  const openConfirmChanges = () => setIsConfirmChangesOpen(true);
  const closeConfirmChanges = () => setIsConfirmChangesOpen(false);

  const onConfirmChanges = async () => {
    try {
      if (survey) {
        const newSurvey = buildNewSurvey(changedItems, survey);
        debug("Saving New Survey", newSurvey);
        await newSurvey.save({
          with: ["items", "segments", "service"],
        });

        await refreshOrder();
        router.push("/");
      }
    } catch (e) {
    } finally {
      closeConfirmChanges();
    }
  };
  const { y } = useWindowScroll();
  const hasScrolledWeightWizardOffPage = y > 360;

  return (
    <PageWrapper noOffset>
      <Box sx={{ position: "relative" }}>
        {hasPendingApproval && (
          <Box sx={{ zIndex: 4, position: "relative" }}>
            <MessageBar>
              <Typography textAlign="center">
                <FormattedMessage id="reviewYourBelongings.messageBar.uneditable.pendingApproval" />
              </Typography>
            </MessageBar>
          </Box>
        )}

        <Container
          disableGutters
          sx={{
            position: "sticky",
            top: 0,
            background: "white",
            zIndex: 1,
          }}
        >
          <Stack
            direction={{ xs: "column-reverse", sm: "row" }}
            alignItems={{ xs: "start", sm: "center" }}
            paddingX={{ xs: 0, sm: 3 }}
            gap={{ xs: 0, sm: 2 }}
            width={1}
          >
            <Box paddingLeft={{ xs: 2, sm: 0 }} pt={4}>
              <BackNav ariaLabel={"ariaLabels.returnHome"} />
            </Box>

            {hasChangedItems && (
              <Stack
                direction="row"
                alignItems="center"
                width={1}
                paddingY={{ xs: "12px", sm: 0 }}
                paddingX={{ xs: 2, sm: 0 }}
                sx={{
                  borderBottom: { xs: 1, sm: 0 },
                  borderBottomColor: { xs: "rgba(0, 0, 0, 0.12)" },
                }}
              >
                <ChangedItemsTag />
                <Button
                  variant="contained"
                  onClick={openConfirmChanges}
                  disabled={isUneditable}
                  sx={{
                    ml: "auto",
                    py: 2,
                    flex: 1,
                    maxWidth: 200,
                  }}
                >
                  <FormattedMessage id="reviewYourBelongings.submitButton" />
                </Button>
              </Stack>
            )}
          </Stack>
        </Container>

        <Divider sx={{ display: { xs: "none", sm: "block" } }} />

        <Container
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              pt: {
                xs: 0,
                sm: 4,
                md: 8,
              },
              display: "grid",
              gridTemplateColumns: {
                xs: "1f",
                md: "7fr 5fr",
              },
              gridTemplateRows: {
                xs: "1fr",
                md: "min-content 1fr",
              },
              rowGap: 3,
              columnGap: {
                md: 11,
              },
              // Needed to get position: sticky to work with grid
              alignItems: "start",
            }}
          >
            <Stack tabIndex={0} gap={1}>
              <H1>
                <FormattedMessage id="reviewYourBelongings.title" />
              </H1>
              <P>
                <FormattedMessage id="reviewYourBelongings.message1" />
              </P>
              <P>
                <FormattedMessage
                  id="reviewYourBelongings.message2"
                  values={{
                    link: (
                      <Link href="/support">
                        <FormattedMessage id="reviewYourBelongings.contactLink" />
                      </Link>
                    ),
                  }}
                />
              </P>
            </Stack>

            <Box
              sx={{
                gridColumn: {
                  xs: "auto",
                  md: "2 / 3",
                },
                gridRow: {
                  xs: "auto",
                  md: "1 / -1",
                },
                backgroundColor: "white",
                position: "sticky",
                top: 84,
                zIndex: 2,
              }}
            >
              <Box
                sx={{
                  display: {
                    xs: hasScrolledWeightWizardOffPage ? "none" : "block",
                    md: "block",
                  },
                }}
              >
                <WeightWizard />
              </Box>
              <Box
                sx={{
                  left: {
                    xs: "50%",
                  },
                  display: {
                    xs: hasScrolledWeightWizardOffPage ? "block" : "none",
                    md: "none",
                  },
                  zIndex: 2,
                }}
              >
                <MiniWeightWizard />
              </Box>
            </Box>
            <Box>
              <SurveyItemSummary />
            </Box>
          </Box>

          <ConfirmSurveyChangesDialog
            open={isConfirmChangesOpen}
            onCancel={closeConfirmChanges}
            onConfirm={onConfirmChanges}
          />
        </Container>
      </Box>
    </PageWrapper>
  );
}
