import { ReactNode } from "react";
import { Stack } from "@mui/system";

export function MessageBar({ children }: { children: ReactNode }) {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      paddingY="14px"
      sx={{
        backgroundColor: "#F2F8FC",
        color: "#01314A",
      }}
    >
      {children}
    </Stack>
  );
}
