export function HeavyIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6532 4.80661C11.2267 4.80661 11.791 4.80661 12.3614 4.80661C13.0554 4.80661 13.4362 5.11105 13.6577 5.83836C14.5725 8.85103 15.4855 11.8642 16.3967 14.878C16.7395 16.0124 16.2101 16.8022 15.114 16.8022H1.88337C0.788083 16.8022 0.26092 16.0115 0.603766 14.8755C1.51398 11.8612 2.42671 8.84769 3.34198 5.83502C3.56347 5.10855 3.94879 4.80661 4.64055 4.80661C5.20715 4.80661 5.77831 4.80661 6.34795 4.80661C5.92698 3.01503 6.14391 2.03499 7.1148 1.286C7.51528 0.975913 7.99221 0.807477 8.48199 0.803155C8.97177 0.798834 9.45111 0.958832 9.85605 1.26181C10.8406 1.99579 11.0682 2.99334 10.6532 4.80661ZM9.71952 3.4721C9.72071 3.20723 9.65054 2.94792 9.51788 2.72684C9.38522 2.50577 9.19599 2.33284 8.97407 2.22986C8.75216 2.12687 8.50747 2.09845 8.27088 2.14818C8.0343 2.1979 7.8164 2.32354 7.64466 2.50925C7.47292 2.69497 7.35503 2.93244 7.30586 3.19173C7.25669 3.45102 7.27843 3.72052 7.36836 3.96623C7.45828 4.21195 7.61236 4.42288 7.81116 4.57244C8.00996 4.722 8.24457 4.80348 8.48543 4.80661C8.64633 4.80882 8.80603 4.77597 8.95532 4.70996C9.10462 4.64395 9.24055 4.5461 9.35528 4.42203C9.47001 4.29797 9.56126 4.15015 9.62377 3.98711C9.68629 3.82407 9.71883 3.64904 9.71952 3.4721Z"
        fill="#01314A"
      />
    </svg>
  );
}
