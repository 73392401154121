import { useState, useEffect } from "react";
import { Order } from "models";
import { debug, envEnabled } from "tools";

/**
 * Hook to get an items current claim from the order data (if present)
 * NOTE: see OrderLoader.tsx for ex. `?include=inventory_tracker` depot order calls
 * @param item The item to find the claim for
 * @returns Claim | undefined
 */
export async function loadOrders() {
  const { data: orders } = await Order.includes([
    "origin",
    "destination",
  ]).all();

  debug(
    envEnabled("NEXT_PUBLIC_MOCK_REQUESTS")
      ? "Updating MOCKED orders"
      : "Updating orders",
    orders
  );

  return orders;
}

export function useOrders() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState();
  useEffect(() => {
    loadOrders()
      .then((orders) => {
        setOrders(orders);
      })
      .catch((e) => setErrors(e))
      .finally(() => setLoading(false));
  }, []);
  return { orders, loading, errors };
}
